.pageDetails .custom-bg {
    background-color: #0E6216;
    color: #ffffff;
}

.pageDetails .body {
    background-color: #DBDBDB;
    /* Cor de fundo desejada */
}

.pageDetails .body-card {
    background-color: #FFFFFF;
    /* Cor de fundo desejada */
}

.pageDetails .lesson-accordion .accordion-header button {
    background-color: white;
    color: black;
}

.pageDetails .button-bookmark {
    background-color: transparent;
    border: none;
    font-size: 24px;
}

.pageDetails .button-bookmark:hover {
    background-color: transparent;
    border: none;
    color: gold;
    opacity: 1;
}

.pageDetails .cancel-rating {
    margin-left: 5px;
    border: 2px solid #dc3545;
    background-color: #dc3545;
}
  
.pageDetails .cancel-rating:active {
    background-color: white;
    color: #dc3545;
    border: 2px solid white;
}

.pageDetails .submit-rating {
    border: 2px solid #0E6216;
    background-color: #0E6216;
}

.pageDetails .link {
    text-decoration: none;
    color: gold;
}

.course-details .detail-header-btn {
    border: 2px solid #0E6216;
    background-color: #0E6216;
    color: white;
}

.course-details .detail-header-btn:active {
    border: 2px solid #0E6216;
    background-color: white;
    color: #0E6216;
}

.course-details .download-certificate-icon {
    margin-right: 5px;
    fill: white;
    font-size: 20px;
}

.course-details .detail-header-btn:active .download-certificate-icon {
    fill: #0E6216;
}

.pageDetails a {
    text-decoration: none;
    color: gold;
}
.course-details .flag-is-professor {
    background-color: #0E6216;
    color: white;
    font-weight: bold;
    padding: 0 10px;
    border-radius: 5px;
    display: inline-block;
    text-align: left;
}

.delete {
    padding: 2px 10px;
    background-color: #c42f2f;
    border-radius: 10px;
    text-align: right;
    color: white;
}

.accordion-categories .divCompleted{
    display: flex;
    justify-content: end;
    position: absolute;
    right: 5px;
    top: 5px;
}

.accordion-categories .img{
    width: 100%;
    height: 100%;
    aspect-ratio: 16/9;
    border-radius: 10px;
    margin-right: 10px;
    margin-bottom: 5px;
}

.accordion-categories .title{
    display: flex;
    align-items: center;
}

.accordion-categories span.completed-flag {
    font-weight: bold;
    color: #0E6216;
    border: 1px solid #0E6216;
    padding: 2px 5px;
    border-radius: 5px;
    height: fit-content;
}

@media (max-width:991.98px){
    .accordion-categories .divCompleted{
        display: block;
        position: relative;
        margin-left: 5px;
        margin-bottom: 15px;
    }

    .accordion-categories .img{
        width: 100%;
    }

    .accordion-categories .title{
        margin-top: 10px;
    }
}