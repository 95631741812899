@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;800;900&amp;family=Raleway:wght@400;600;700;900&amp;display=swap');

body, html {
    font-family: Nunito Sans, sans-serif;
}

.fundo {
    background-color: rgb(248, 249, 250)
}


.containe-fluid {
    max-width: '70%'
}

.home-card .cardAula {
    width: 100% !important;
    cursor: pointer;
}

.home-card a {
    text-decoration: none !important;
    color: black;
}

.container-input {
    position: relative; 
    background-color: white;
    border: solid 1px lightgrey;
    border-radius: 8px;
    display: flex;
    align-items: center;
    height: 46px;
}

.input-search, .input-search:focus, .input-search:hover, .input-search:active{
    flex: 1;
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow:none;
    margin-left: 8px;
    font-size: 18px;
    color: grey;
}

.icon-search {
    position: absolute; 
    right: 14px; 
    top: 50%; 
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    color: darkgrey;
}