.container-student-lesson-page * {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.react-player {
  width: 100% !important;
  height: auto !important;
  aspect-ratio: 16/9 !important;
}

.container-student-lesson-page .flag-completed-lesson, .container-student-lesson-page .flag-is-professor {
  background-color: #0E6216;
  color: white;
  font-weight: bold;
  padding: 0 10px;
  border-radius: 5px;
}

.container-student-lesson-page .flag-not-enrolled {
  background-color: #dc3545;
  color: white;
  font-weight: bold;
  padding: 0 10px;
  border-radius: 5px;
}

.container-student-lesson-page .button-navigate {
  font-weight: bold;
  background-color: #0E6216;
  border-color: #0E6216;
  border-radius: 10px; 
  margin-bottom: 0.5rem;
}

.container-student-lesson-page .flag-completed-lesson, .container-student-lesson-page .buttons{
  margin-left: 1px;
}

.container-student-lesson-page .flag-completed-lesson, .container-student-lesson-page .buttons .btn-resources {
  margin-top: 15px;
}

@media (max-width:991.98px){
  .container-student-lesson-page .flag-completed-lesson, .container-student-lesson-page .buttons{
    flex-direction: column;
  }
}