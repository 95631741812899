.home {
  height: 100%;
  width: 50%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: white;

  transition: 0.5s;
  color: white;

  box-shadow: 1px 1px 5px rgb(213, 213, 213);
}

.home a {
  box-shadow: none !important;
  text-decoration: none !important;
}

.space {
  height: 10rem;
}

.spaces {
  height: 1rem;
}

.sig {
  height: 15rem;
  transform: skewY(-20deg);
}

.cardSide {
  border: none;
  width: 100%;
  height: 100%;
}

.link {
  color: black;
  font-size: large;
  font-weight: bold;
}

.link:hover {
  color: red;
  text-decoration: none;
  cursor: pointer;
}

.li {
  border: none;
}

/* .cardSide-img-top {
  width: 75%;
  height: 2vw;
  object-fit: contain;
  border-top: 0px;
  margin-top: 0px;
  padding-top: 0px;
} */

/* .md > | Medium devices and up (tablets) 768px and up */
@media (min-width: 768px) {
    .show-mobile-version {
        display: none !important;
    }
}

/* .sm < | Small devices and down (landscape, phones) less than 768px */
@media (max-width: 767.98px) {
    .show-mobile-version {
        display: block !important;
    }
}
