html,
body {
  margin: 0;
  height: 100%;
  background-color: rgb(248, 249, 250);
}

.formulario-cadastro {
  box-shadow: 1px 1px 5px rgb(179, 179, 179);
  margin-bottom: 10px;
}

.container-new-course .form-label {
  margin-bottom: 0;
}

.input-anotation {
  font-size: 13px;
  color: lightslategray;
}

.container-new-course .register-btn {
  background-color: #0E6216;
  border-color: #0E6216;
}

.container-new-course .cancel-btn {
  background-color: #dc3545;
  border-color: #dc3545;
}

.container-new-course .submit-form:active {
  background-color: white;
  color: #0E6216;
  border-color: white;
}

.container-course h2 {
  font-weight: bold;
  color: #0E6216;
}

.container-course input,
.container-new-course textarea,
.container-course .submit-form {
  outline: none !important;
  box-shadow: none !important;
}

.container-new-course .image-for-input-file {
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
}

.container-new-course .error {
  border-color: #dc3545 !important;
  color: #dc3545 !important;
}

.container-new-course .ok {
  border-color: #0E6216 !important;
  color: #0E6216 !important;
}

.container-new-course .file-input-span .ok,
.container-new-course .file-input-span .error {
  font-weight: bold;
}

.container-course .pl0 {
  padding-left: 0 !important;
}

.container-course .pr0 {
  padding-right: 0 !important;
}

.container-new-course img.image-for-input-file {
  max-height: 50vh;
}

.container-new-course textarea.input-description {
  height: 20vh;
}

.container-new-course textarea.input-content {
  height: 30vh;
}

.container-course .input-learning:focus {
  border: 1px solid #ced4da;
}

.container-course .submit-form-learning,
.container-course .submit-add-lesson {
  font-weight: bold;
}

.container-course .submit-add-lesson {
  margin-left: 0 !important;
}

.container-course .submit-form-learning,
.container-course .submit-add-lesson {
  margin-left: 5px;
  width: calc(20% - 5px);
  border: 2px solid #0E6216;
  background-color: #0E6216;
}

.container-course .submit-form-learning:active {
  border: 2px solid white;
  background-color: white;
  color: #0E6216;
}

.container-course .edit-learning {
  margin-left: 5px;
  border: 2px solid #0E6216;
  background-color: #0E6216;
}

.container-course .remove-learning {
  margin-left: 5px;
  width: calc(10% - 5px);
  border: 2px solid #dc3545;
  background-color: #dc3545;
}

.container-course .cancel-learning {
  margin-left: 5px;
  border: 2px solid #dc3545;
  background-color: #dc3545;
}

.container-course .remove-learning:active {
  background-color: white;
  color: #dc3545;
  border: 2px solid white;
}

.container-course .label-submit-learning span {
  color: #0E6216;
  font-weight: bold;
}

.container-new-course textarea::-webkit-scrollbar {
  display: none;
}

.container-new-course .alert {
  margin-bottom: 5px;
}

@media (max-width: 991.98px) {

  .container-course .pl0,
  .container-course .pr0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .container-new-course img.image-for-input-file {
    height: 30vh;
  }
}

.modal .cancel-modal-btn  {
  width: calc(10% - 5px);
  border: 2px solid #dc3545;
  background-color: #dc3545;
}

.modal .cancel-modal-btn:active {
  background-color: white;
  color: #dc3545;
  border: 2px solid white;
}

.modal .remove-modal-btn  {
  width: calc(10% - 5px);
  border: 2px solid #0E6216;
  background-color: #0E6216;
}

.modal .remove-modal-btn:active {
  background-color: white;
  color: #0E6216;
  border: 2px solid white;
}




.btn-edit-categories, .btn-edit-categories:active, .btn-edit-categories:hover {
  background-color: #0E6216 !important;
  color: white !important;
  border-color:#0E6216 !important;
}


.accordion-categories h2 button {
  box-shadow: none !important;
  background-color: white !important;
  color: black !important;
}

.btn-edit-lessons-order {
  padding: 0 5px;
  background-color:  white;
  color: #adb5bd;
  border-color: #adb5bd;
}

.btn-edit-lessons-order:hover, .btn-edit-lessons-order:active {
  background-color:  white;
  color: rgb(68, 67, 67);
  border-color: rgb(68, 67, 67);
}