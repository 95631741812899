.code-span {
  width: 10rem;
  text-align: left;
}

.box{
  width: 15rem;
}

.flag {
  border-radius: 10px;
  padding: 2px 10px;
  color: white;
}

.flag-used {
  background-color: #0E6216;
}

.flag-not-used {
  background-color: rgb(209, 209, 9);
}

.goto {
  padding: 2px 10px;
  background-color: #2d3d94;
  border-radius: 10px;
  text-align: right;
  color: white;
}