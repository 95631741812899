@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;800;900&amp;family=Raleway:wght@400;600;700;900&amp;display=swap');

body, html {
    font-family: Nunito Sans, sans-serif;
}

.fundo {
    background-color: rgb(248, 249, 250)
}


.containe-fluid {
    max-width: '70%'
}

.home-card .cardAula {
    width: 100% !important;
    cursor: pointer;
}

.home-card a {
    text-decoration: none !important;
    color: black;
}