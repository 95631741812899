.gear{
  font-size: 20px;
  border: none;
  color: black;
  background-color: transparent;
}

.gear:hover {
  color: black;
  font-size: 22px;
  background-color: transparent;
  transition: 0.2s ease-in-out;
}

.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
  color: black;
  background-color: transparent;
}

.dropdown-toggle::after {
  display: none !important; 
}

.modal-invite * {
  box-shadow: none !important;
}

.dropdown-item-no-highlight:focus {
  background-color: transparent !important;
}

/* .md > | Medium devices and up (tablets) 768px and up */
@media (min-width: 768px) {
    .about-me-column {
        width: 65%;
    }

    .dropdown-position-md-lg {
        position: absolute;
        margin-left: 90%;
    }
}

/* .sm < | Small devices and down (landscape, phones) less than 768px */
@media (max-width: 767.98px) {
    .navbar-brand-visibility {
        display: none;
    }

    .nav-collapse{
      display: none;
    }
}
