.code-ra {
  width: 2rem;
  text-align: left;
  font-weight: bold;
  color: #0b4e12;
}

.code-span {
  width: 2rem;
  text-align: left;
}

.box{
  width: 2rem;
}

.flag {
  border-radius: 10px;
  padding: 2px 10px;
  color: white;
}

.flag-used {
  background-color: #0E6216;
}

.flag-not-used {
  background-color: rgb(209, 209, 9);
}

.pillwrapper {
  display: inline-block;
  border-radius: 10px;
  padding: 2px 10px;
}

.share {
  background-color: #2d3d94;
  text-align: right;
  color: white;
}

.delete {
  background-color: #c42f2f;
  text-align: left;
  color: white;
}

.break-word {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.tag-role{
  color: #0b4e12;
  font-weight: bold;
}

.button-div{
  padding-right: 50px;
}

.button-order{
  background-color: #0E6216;
  border-color: #0E6216;
}

.button-order:hover{
  background-color: #0b4e12;
  border-color: #0b4e12 ;
}

@media (max-width:991.98px){

  .button-div{
    padding-right: 0px;
  }

  .button-order{
    font-size: 15px;
  }
}