.questionLesson .custom-bg {
    background-color: #0E6216;
    color: #ffffff;
}

.questionLesson .button-question{
    min-width: 230px;
    font-weight: bold;
    background-color: #0E6216; 
    border-color: #0E6216;
    border-radius: 10px; 
}

.questionLesson .body {
    background-color: #DBDBDB;
    /* Cor de fundo desejada */
}

.questionLesson .body-card {
    background-color: #FFFFFF;
    /* Cor de fundo desejada */
}

/* .questionLesson .lesson-accordion .accordion-header {
    display: flex;
    flex-direction: column;
} */

.questionLesson .lesson-accordion .accordion-header button {
    background-color: white;
    color: black;
}

.questionLesson .cancel-question {
    margin-left: 5px;
    border: 2px solid #dc3545;
    background-color: #dc3545;
}

.questionLesson .cancel-question:active {
    background-color: white;
    color: #dc3545;
    border: 2px solid white;
}

.questionLesson .submit-question {
    border: 2px solid #0E6216;
    background-color: #0E6216;
}

.questionLesson .flag-is-professor {
    background-color: #0E6216;
    color: white;
    font-weight: bold;
    padding: 0 10px;
    border-radius: 5px;
}