.cardAula:hover .card-footer {
    background-color: rgb(207, 207, 207);
}

.cardAula:hover {
    /* background-color: rgb(207, 207, 207); */
    transform: scale(1.03);
    transition: ease-in-out 0.1s;
    -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.15);
}


.cardAula {
    margin-bottom: 1rem;
    background-color: rgb(247, 247, 247);
    height: 100%;
    border: none;
    -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.25);
    box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.25);
}

.cardAula-title {
    font-weight: 900;
    flex-grow: 1;
}

.cardAula-description {
    font-size: 13px;

    overflow: hidden;
    height: 100px;
}

.cardAula-img-top {
    width: 100%;
    height: 15vw;
    object-fit: cover;
}

.cardAula-footer {
    border-top: none;
    background-color: rgb(247, 247, 247);
}

.cardAula-body {
    padding-bottom: 0px;
}


.progress-custom {
    background-color: purple;
    color: white;
}

.cardAula .completed-percentage-flag {
    position: absolute;
    bottom: 4px;
    right: 6px;
    color: #0E6216;
    font-weight: bold;
}