.custom-bg {
    background-color: #0E6216;
    color: #ffffff;
}

.body {
    background-color: #DBDBDB;
    /* Cor de fundo desejada */
}

.body-card {
    background-color: #FFFFFF;
    /* Cor de fundo desejada */
}

.lesson-accordion .accordion-header button {
    background-color: white;
    color: black;
}
